<template>
  <div class="container">
    <!--<img src="http://ims.qitianzhen.cn/Backimg/62yearbg1.jpg" style="position: absolute;width:100%;height: 100%;top:0;left:0" alt="">-->
    <a-row>
      <a-col :span="15">
        <div class="page-left">
          <a-carousel autoplay>
            <div class="carousel">
              <img
                src="http://sk-ims-cabinet.sikegroup.com/FjkNX6vBgVFI44fDJ6bXCPP3J65Q"
                alt=""
              />
              <p class="carousel-title">塑造企业与客户的练习</p>
              <p class="carousel-detail">
                客户精细化管理，数据可视化，服务流程化，
                标准规范，一切尽在掌握。
              </p>
            </div>
            <div class="carousel">
              <img
                src="http://sk-ims-cabinet.sikegroup.com/FjkNX6vBgVFI44fDJ6bXCPP3J65Q"
                alt=""
              />
              <p class="carousel-title">塑造企业与客户的练习</p>
              <p class="carousel-detail">
                客户精细化管理，数据可视化，服务流程化，
                标准规范，一切尽在掌握。
              </p>
            </div>
          </a-carousel>
        </div>
      </a-col>
      <a-col :span="9">
        <div class="page-right">
          <div class="login">
            <img
              class="logo"
              src="http://sk-ims-cabinet.sikegroup.com/FlTURkt5QlBY9ZFDmmBrUg8GIdxU"
              alt=""
            />
            <div class="group-from">
              <div class="group-label">账号</div>
              <a-input
                v-model="username"
                class="group-item"
                placeholder="请输入账号"
              />
            </div>
            <div class="group-from">
              <div class="group-label">密码</div>
              <a-input-password
                autocomplete="false"
                v-model="password"
                class="group-item"
                placeholder="请输入密码"
              />
            </div>
            <a-button
              :loading="loginBtn"
              :disabled="loginBtn"
              style="width: 100%;"
              type="primary"
              @click="handleSubmit"
              >确定</a-button
            >
            <div style="display:flex;margin-top: 27px">
              <a-checkbox>记住密码</a-checkbox>
              <a style="color: #2994FF;flex: 1;text-align: right">忘记密码?</a>
            </div>
            <span class="other-login">其它方式登录</span>
            <div style="display: flex;margin: auto">
              <div class="qy-wechat">
                <a-avatar
                  class="wechat-img"
                  :size="60"
                  icon="user"
                  src="http://sk-ims-cabinet.sikegroup.com/FoKYdwCr25FeaSVU3QdX38cD2XFJ"
                />
                <div class="wechat-text">企业微信</div>
              </div>
            </div>
            <p class="register">
              还没有账号 &nbsp;&nbsp;<span style="color:#2994FF">免费注册</span>
            </p>
          </div>
        </div>
      </a-col>
    </a-row>
	<div class="copyright">© 2024.深圳思珂企业管理咨询有限公司.版权所有 <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2023026923号-1</a></div>
  </div>
</template>

<script>
import axios from 'axios'
import Vue from 'vue'
// import myAxios from 'assets/axios/axios';
const http = axios.create({
  baseURL: 'https://sapi.sikegroup.com',
  withCredentials: false,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
  },
  transformRequest: [
    function (data) {
      let newData = ''
      for (let k in data) {
        if (data.hasOwnProperty(k) === true) {
          newData +=
            encodeURIComponent(k) + '=' + encodeURIComponent(data[k]) + '&'
        }
      }
      return newData
    }
  ]
})
export default {
  data () {
    return {
      customActiveKey: 'tab1',
      loginBtn: false,
      // login type: 0 email, 1 username, 2 telephone
      loginType: 0,
      requiredTwoStepCaptcha: false,
      stepCaptchaVisible: false,
      form: this.$form.createForm(this),
      state: {
        time: 60,
        loginBtn: false,
        // login type: 0 email, 1 username, 2 telephone
        loginType: 0,
        smsSendBtn: false
      },
      username: '',
      password: ''
    }
  },
  created () {},
  methods: {
    handleUsernameOrEmail (rule, value, callback) {
      const { state } = this
      const regex = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,3}){1,2})$/
      if (regex.test(value)) {
        state.loginType = 0
      } else {
        state.loginType = 1
      }
      callback()
    },
    handleTabClick (key) {
      this.customActiveKey = key
      // this.form.resetFields()
    },
    handleSubmit () {
      let self = this
      if (!self.username) {
        this.$message.error('请输入用户名')
        return false
      }
      if (!self.password) {
        this.$message.error('请输入密码')
        return false
      }
      self.loginBtn = true
      let data = { mobile: self.username, LoginType: 'skim' }

      http({
        method: 'get',
        url: '/v1/before_token',
        params: data
      }).then(res => {
        if (res.data.code == 1) {
          let params = {}
          params = {
            mobile: self.username,
            pwd: self.password,
            appid: res.data.data[0].CompanyId
          }

          window.sessionStorage.setItem(
            'TOGGLE_LOGIN_MSG',
            JSON.stringify(params)
          )
          //请求token
          self.$axios.get(1204, { LoginType: 'skim' }, res => {
            if (res.data.code == 200) {
              //获取系统基本配置信息
              let list = {}
              this.$axios.post(5836112, { Label: 'xitongpeizhi' }, res => {
                if (res.data.code == 1) {
                  for (let i = 0; i < res.data.data.length; i++) {
                    if (
                      res.data.data[i].Type == '图片上传' ||
                      res.data.data[i].Type == '单选框' ||
                      res.data.data[i].Type == '多选框'
                    ) {
                      list[res.data.data[i].Label] = JSON.parse(
                        res.data.data[i].Value
                      )
                    } else {
                      list[res.data.data[i].Label] = res.data.data[i].Value
                    }
                  }
                  this.$store.commit('TOGGLE_SYSTEM_BASE_MSG', list)
                }
              })
              //获取菜单列表
              self.$axios.get(1213, {}, result => {
                window.sessionStorage.setItem(
                  'MenuList',
                  JSON.stringify(result.data.data)
                )
                this.$store.commit('TOGGLE_PAGE_MENU_LIST', result.data.data)
                if (this.$route.query.redirect) {
                  window.location.href = this.$route.query.redirect
                } else {
                  //从哪个页面跳来登陆的就跳回哪个页面
                  if (window.sessionStorage.getItem('defaultActiveKey')) {
                    let list = JSON.parse(
                      window.sessionStorage.getItem('multiTabList')
                    )
                    window.location.href =
                      list[
                        window.sessionStorage.getItem('defaultActiveKey')
                      ].path
                  } else {
                    window.location.href = '/'
                  }
                }
              })
            } else {
              self.loginBtn = false
              self.$message.error(res.data.message)
            }
          })
        } else {
          setTimeout(() => {
            self.loginBtn = false
          }, 600)
        }
      })
      self.loginBtn = false
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  min-height: 100%;
  background-size: 100%;
  /*padding: 110px 0 144px;*/
  position: relative;
}
.page-left {
  width: 100%;
  background: url('http://sk-ims-cabinet.sikegroup.com/FsLHuLGUdgx-4rSoBdInrOVGSnRh')
    no-repeat;
  background-size: 100% 100%;
  margin: auto;
  text-align: center;
  padding: 278px 0px 50px 0px;
  .carousel {
    margin: auto;
    /*padding: 360px 0px ;*/
    text-align: center;
    img {
      margin: auto;
    }
    .carousel-title {
      font-size: 19px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 25px;
      color: rgba(255, 255, 255, 1);
      text-align: center;
      margin-top: 110px;
      margin-bottom: 23px;
    }
    .carousel-detail {
      width: 326px;
      text-align: center;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
      letter-spacing: 2px;
      opacity: 0.66;
      margin: auto;
      margin-bottom: 60px;
    }
  }
}
.page-right {
  background: #ffffff;
  padding: 173px 0px 0px 0px;
  .login {
    width: 305px;
    margin: auto;
    text-align: center;
    .logo {
      margin-bottom: 26px;
    }
    .group-from {
      width: 100%;
      margin-bottom: 22px;
      .group-label {
        text-align: left;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(102, 102, 102, 1);
        margin-bottom: 5px;
      }
      .group-item {
        width: 100%;
      }
    }
    .other-login {
      display: block;
      width: 100%;
      position: relative;
      margin-top: 35px;
      text-align: center;
      margin-bottom: 33px;
    }
    .other-login:before {
      content: '';
      width: 32%;
      height: 1px;
      background: rgba(223, 227, 235, 1);
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    .other-login:after {
      content: '';
      width: 32%;
      height: 1px;
      background: rgba(223, 227, 235, 1);
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    .qy-wechat {
      margin: auto;
      .wechat-text {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(153, 153, 153, 1);
      }
    }
    .register {
      margin-top: 60px;
      text-align: center;
    }
  }
}

.top {
  text-align: center;

  .header {
    height: 44px;
    line-height: 44px;

    .badge {
      position: absolute;
      display: inline-block;
      line-height: 1;
      vertical-align: middle;
      margin-left: -12px;
      margin-top: -10px;
      opacity: 0.8;
    }

    .logo {
      height: 44px;
      vertical-align: top;
      margin-right: 16px;
      border-style: none;
    }

    .title {
      font-size: 33px;
      color: rgba(0, 0, 0, 0.85);
      font-family: Avenir, 'Helvetica Neue', Arial, Helvetica, sans-serif;
      font-weight: 600;
      position: relative;
      top: 2px;
    }
  }
  .desc {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.45);
    margin-top: 12px;
    margin-bottom: 40px;
  }
}

.main {
  position: absolute;
  top: 50%;
  right: 40px;
  transform: translateY(-50%);
  min-width: 260px;
  width: 420px;
  margin: 0 auto;
  background: #fff;
  border-radius: 8px;
  padding: 25px;
}

.footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 0 16px;
  margin: 48px 0 24px;
  text-align: center;
  .links {
    margin-bottom: 8px;
    font-size: 14px;
    a {
      color: rgba(0, 0, 0, 0.45);
      transition: all 0.3s;
      &:not(:last-child) {
        margin-right: 40px;
      }
    }
  }
  .copyright {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
  }
}
.user-layout-login {
  label {
    font-size: 14px;
  }

  .getCaptcha {
    display: block;
    width: 100%;
    height: 40px;
  }

  .forge-password {
    font-size: 14px;
  }

  button.login-button {
    padding: 0 15px;
    font-size: 16px;
    height: 40px;
    width: 100%;
  }

  .user-login-other {
    text-align: left;
    margin-top: 24px;
    line-height: 22px;

    .item-icon {
      font-size: 24px;
      color: rgba(0, 0, 0, 0.2);
      margin-left: 16px;
      vertical-align: middle;
      cursor: pointer;
      transition: color 0.3s;

      &:hover {
        color: #1890ff;
      }
    }

    .register {
      float: right;
    }
  }
}
.copyright{text-align: center;margin-top: 20px;}
</style>
